import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../containers/Home/Layout";
import { interiorTheme } from "common/theme/interior";
import "common/assets/css/main-page.css";

import Seo from "components/seo";

import TitleBar2 from "common/components/Titlebar2";
import SingleServicePage from "../containers/Home/pages.style";
import ServiceSectionTwo from "../containers/Sections/ServiceSectionTwo";
import ServiceSectionThree from "../containers/Sections/ServiceSectionThree";
import ServiceSectionOne from "../containers/Sections/ServiceSectionOne";
import ServiceSectionFour from "../containers/Sections/ServiceSectionFour";

const CustomizedWigforWomen = () => {

  const seoData = {
    title: 'Natural Look Customized Wigs For Women In Delhi - Radiance',
    description: 'Natural Look Customized Wigs For Women In Delhi at Radiance Hair Studio. Find the perfect wig to enhance your style and confidence. Visit us today.',
    keywords: ['Customized Wigs For Women, Customized Wigs For Women in Delhi, Customized Wigs for Women Price, Customized Wigs For Women Near Me']
  };

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Service",
    "@id": "Service",
    "alternateName": "Radiance Hair Studio",
    "areaServed": "New Delhi",
    "image": [
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/service-images/Customized-Wig-for-Women.jpg",
      "https://www.radiancehairstudio.com/service-images/Customized-Wig-for-Women.jpg",
      "https://www.radiancehairstudio.com/service-images/Customized-Wig-for-Women.jpg",
      "https://www.radiancehairstudio.com/service-images/Customized-Wig-for-Women.jpg",
      "https://www.radiancehairstudio.com/customized-wig-women.png",
      "https://www.radiancehairstudio.com/customized-wig-women1.png",
      "https://www.radiancehairstudio.com/help-image-1.png"
    ],
    "logo": "https://www.radiancehairstudio.com/static/radiance-logo-721b0cd4def3d400c04ba899afd38810.png",
    "additionalType": "https://www.radiancehairstudio.com/customized-wigs-for-women.php",
    "category": "https://www.radiancehairstudio.com/customized-wigs-for-women.php",
    "description": "Natural Look Customized Wigs For Women In Delhi at Radiance Hair Studio. Find the perfect wig to enhance your style and confidence. Visit us today.",
    "disambiguatingDescription": "Customized Wigs For Women, Customized Wigs For Women in Delhi, Customized Wigs for Women Price, Customized Wigs For Women Near Me",
    "serviceType": "Customized Wig for Women"
  };  

  const sectionS1 = [
    {
      title: "Customized Wigs for Women",
      img: "/custom-wigs.png",
    },
    {
      title: "Wig for Women’s",
      img: "/womens-wig.png",
    },
    {
      title: "Hair Replacement",
      img: "/hair-replacement.png",
    },
    {
      title: "Non-Surgical Hair Replacement",
      img: "/non.png",
    }
  ];

  const sectionS2 = [
    {
      img: "/service-images/Customized-Wig-for-Women.jpg",
      content: (
        <div>
          <h2>Affordable Customized Wig for Women's Delhi</h2>
          <p>
            Wigs can be used if you are losing an excessive amount of hair or
            anything related to that. Custom Made wigs are something which can be
            made according to your preference and most importantly which will suit
            you perfectly!.
          </p>
          <h2>Complete Natural Hair, Just Like Yours!</h2>
          <p>
            You are still imagining that how it's possible to get the perfect wig
            which can reflect your mood or maybe your personality. Leave that on
            RADIANCE HAIR STUDIO. They always have your back no matter what! Just
            tell them how you want your hair to look, no doubt they have the best
            collection of wigs or if even they don't have the exact one. No
            worries! We will make your wig or it can be <a href="/contact.php" style={{ color: "#373737"}}>called</a> your original hair
            as you want. If you have brown hair and you don't want to change that
            shade because you think it suits you, well they will make a brown wig
            which will look exactly same as your natural hair and trust me you are
            gonna love it.
          </p>
          <p>
            {" "}
            Here in <a href="/" style={{ color: "#373737"}}>RADIANCE HAIR STUDIO</a>, We have already a huge collection of
            wigs but they always keep their customer at the top of their priority
            list so they have come with this exclusive custom made wig thing!.
            Apart from this, there's another reason to choose RADIANCE as their
            wigs are made of 100 percent natural human hair which is actually a
            great thing.
          </p>
        </div>
      ),
    },
  ];
  
  const sectionS3 = [
    {
      smallImg: "/customized-wig-women.png",
      largeImg: "/customized-wig-women1.png",
      content: (
        <div>
          <h2>Benefits: Stay Trendy</h2>
          <p>
            Most of you already know, how wigs can be a life savior. But there's
            definitely a fine line between normal wigs and custom made wigs.
          </p>
          <ul>
            <li>
              {" "}
              <b>Colour</b>: Normal wigs can't really match your natural hair
              color always. Here we offer you almost every shade as per your
              preferences, so you can easily choose your original hair color.
            </li>
            <li>
              <b>Style</b>: If you have curly hair or wavy hair or even straight
              hair, custom made wig would perfectly match your hair type as the is
              wig is being made only for you.{" "}
            </li>
            <li>
              <b>Length</b>: length is a big issue nowadays because we don't
              always fond of long hair or short hair. As RADIANCE HAIR STUDIO's
              wigs are made of 100 percent natural human hair so it really doesn't
              matter if it's short or long. You are gonna slay anyway!
            </li>
            <li>
              <b>Better Fit</b>: normal wigs don't always fit on you! But if you
              are going for a custom made wig then you are gonna have something
              which will fit you same as your natural hair.{" "}
            </li>
          </ul>
          <p>
            All the worries those were chaining you down from adopting a natural
            human hair wig is now solved. Get urself one and fight that DEVIL with
            all your WILL!
          </p>
        </div>
      ),
    },
  ];
  
  const sectionS4 = [
    {
      title: "Frequently Asked Questions Regarding CustomMade Wig",
      description:
        "We’ve seen people have many queries about wigs and especially when it comes to custom made wigs then there must be a lot of questions.",
      description2: "",
      faq: [
        {
          title: "Should I buy more than one wig?",
          description:
            "Well, If you are choosing a custom made wig then you might not need more than one wig. You are choosing custom made wig because of the fact that you want something which will match your natural hair, we will provide you with a certified hair expert who will suggest the best for you.",
        },
        {
          title: "Which type of wigs will not look artificial?",
          description:
            "You should never ever choose a wig made of synthetic hair. You must always choose a wig which is made of 100 natural human hair, RADIANCE HAIR STUDIO provide you exactly this. This is very very important while choosing a wig, that should never look alike artificial.",
        },
        {
          title:
            "Do I need a full wig if I have thinning hair or partial hair loss?",
          description:
            "Except for a full wig, you are left with just one option which is the topper. But toppers aren't great always. You can ask our hair experts to make a custom made wig with 100 percent natural human hair, it would do a great job.",
        },
        {
          title:
            "What is the difference between readymade wig and custom made a wig?",
          description:
            "Ready Made wigs are good but who doesn't love something which will be only theirs?. With custom made wig you can choose according to your natural hair color, and hair type so that will be only yours!",
        },
      ],
    },
  ];
  

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
      <Seo {...seoData} schemaData={schemaData} />
        <Layout>
          <TitleBar2 title="Customized Wig for Women" banner="/customized-wig-for-women.png"/>
          <SingleServicePage>
            <ServiceSectionOne data={sectionS1} title="Affordable Customized Wig for Women's Delhi" />
            <ServiceSectionTwo data={sectionS2} />
            <ServiceSectionThree data={sectionS3} />
            <ServiceSectionFour data={sectionS4} />
          </SingleServicePage>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default CustomizedWigforWomen;
